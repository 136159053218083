import React from "react";
import styled from "styled-components";

const TLForm = styled.form`
  padding: 3px;
  margin:  auto;
  background: transparent;
`;

const InputFlex = styled.div`
  display: flex;
  justify-content:center;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Input = styled.input`
  width: 60%;
  min-width: 200px;
  margin: 1rem 0 1rem 0;
  ${'' /* border-radius: 4px; */}
  padding: 6px 10px 4px 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: ${({ theme }) => theme.colors.primary};
`;

const A = styled.a`
  font-size: 12px;
`;

const SubscribeButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  margin: 1rem 0;
  color: white;
  width: 300px;
  font-weight: bold;
  font-family: Open Sans Condensed, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  border: none;
  ${'' /* border-radius: 4px; */}
  padding: 0.25rem 1rem;
  transition: all 200ms;
  &:hover {
    cursor: pointer;
    background: white;
    color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.primary};
  }
  &:focus {
    background: white;
    color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.primary};
    outline: none;
  }
`;

export const CallForEntriesLink = styled.a`
  ${({disabled}) => disabled && 'cursor: default;'}
  ${({disabled}) => disabled && 'pointer-events: none;'};
  text-decoration: none;
  text-transform: uppercase;
  background-color: ${({ theme, disabled }) => disabled ? 'grey' : theme.primary};
  margin: 1rem 0;
  color: white;
  width: 300px;
  font-weight: bold;
  font-family: Open Sans Condensed, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  border: none;
  border-radius: 4px;
  padding: 0.6rem 1rem;
  transition: all 200ms;
  &:hover {
    background: white;
    color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.primary};
  }
  &:focus {
    background: white;
    color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.primary};
    outline: none;
  }
`;

const TLP = styled.p`
margin: 0;`;

export const TinyLetterForm = ({ children }) => (
  <TLForm
    action="https://tinyletter.com/pommejournal"
    method="post"
    target="popupwindow"
    onsubmit="window.open('https://tinyletter.com/pommejournal', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true"
  >
    <TLP>
      <label htmlFor="tlemail">{children}</label>
    </TLP>

    <InputFlex>
      <Input
        type="text"
        style={{ width: "300px" }}
        name="email"
        id="tlemail"
        placeholder="your email here"
      />
      <SubscribeButton type="submit">
        SUBSCRIBE TO POMME NEWSLETTER
      </SubscribeButton>
    </InputFlex>

    <input type="hidden" value="1" name="embed" />
  </TLForm>
);

export default TinyLetterForm;
