module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PommeJournal","short_name":"Pomme","start_url":"/","background_color":"#000","theme_color":"#F05530","display":"minimal-ui","icon":"src/images/Simple-Logo-Orange.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135499941-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener noreferrer"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":830,"maxHeight":500,"quality":90,"withWebp":true,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pomme Journal","short_name":"pomme-journal","description":"Pomme Journal is a brand new literary publication striving to be a fresh platform showcasing up-and-coming voices. Get to know us a little, then submit your work! Our first issue will be published fall 2019.","start_url":"/","background_color":"#F05530","theme_color":"#1D5A49","display":"standalone","icon":"src/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
