import React from 'react'
import styled, {css} from 'styled-components'
// import { components as UI } from "./StyledElements";

const StyledImage = styled.img`
  width: 1;
  height: 1;
  margin: 0px;
`
const ListingContainer = styled.div`
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${props => props.theme.secondary};
  }
  background-color: ${props => props.theme.bg};
  max-width: 33%;
  min-width: 300px;
  text-align: center;
  padding: 20px 10px;
`
const ProductTitleLink = styled.a`
  &&& {
    h2: {
      color: red;
      &:hover {
        color: ${props => props.theme.primaryLight};
      }
    }
  }
`

const AnchorBuyButton = styled.a`
  text-align: center;
  flex: center;
  text-decoration: none;
  padding: 0.5em;
  background-color: ${props => props.theme.primary};
  color: white;

  transition: all 250ms ease-in-out;
  &:hover {
    background-color: white;
    color: ${props => props.theme.primary};
    opacity: 1;
    box-shadow: 0 0 0 3px ${({theme}) => theme.primaryLight};
  }
  &:focus {
    background-color: white;
    color: ${props => props.theme.primary};
    opacity: 1;
    box-shadow: 0 0 0 3px ${({theme}) => theme.primaryLight};
  }
`

export const StyledH2 = styled.h2`
  text-transform: capitalize;
  font-family: Open Sans Condensed, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-weight: 400;
  color: ${props => props.theme.primaryLight};
`
{
  /* <a target="_blank"  href="https://www.amazon.com/gp/product/B07PKKB5B3/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07PKKB5B3&linkCode=as2&tag=vango0d-20&linkId=7b8f980be68bf3f3e971e19703ca7c3f"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07PKKB5B3&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=vango0d-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=vango0d-20&l=am2&o=1&a=B07PKKB5B3" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}
const AmazonDetailListing = ({
  ASIN = 'B07PKKB5B3',
  description,
  title,
  order,
  children,
  buyButton,
}) => {
  if (children) {
    return (
      <React.Fragment>
        <ListingContainer>
          {title && (
            <a
              target="_blank"
              href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=vango0d-20&linkId=7b8f980be68bf3f3e971e19703ca7c3f`}
            >
              {title}
            </a>
          )}
          <a
            target="_blank"
            href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=vango0d-20&linkId=7b8f980be68bf3f3e971e19703ca7c3f`}
          >
            <StyledImage
              border="0"
              src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${ASIN}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=vango0d-20`}
            />
          </a>
          <img
            src={`//ir-na.amazon-adsystem.com/e/ir?t=vango0d-20&l=am2&o=1&a=${ASIN}`}
            alt=""
          />
        </ListingContainer>
        {!description && children ? (
          <a
            target="_blank"
            href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=vango0d-20&linkId=7b8f980be68bf3f3e971e19703ca7c3f`}
          >
            {children}
          </a>
        ) : (
          description
        )}
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <ListingContainer>
          {title && (
            <a
              target="_blank"
              href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=vango0d-20&linkId=7b8f980be68bf3f3e971e19703ca7c3f`}
            >
              <StyledH2>
                {order}
                <br></br>
                {title}
              </StyledH2>
            </a>
          )}
          <a
            target="_blank"
            href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=vango0d-20&linkId=7b8f980be68bf3f3e971e19703ca7c3f`}
          >
            <StyledImage
              border="0"
              src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${ASIN}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=vango0d-20`}
            />
          </a>
          <img
            src={`//ir-na.amazon-adsystem.com/e/ir?t=vango0d-20&l=am2&o=1&a=${ASIN}`}
            alt=""
          />
          {buyButton && (
            <AnchorBuyButton
              target="_blank"
              href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=vango0d-20&linkId=7b8f980be68bf3f3e971e19703ca7c3f`}
            >
              BUY ON AMAZON
            </AnchorBuyButton>
          )}
          <p>{description}</p>
        </ListingContainer>
      </React.Fragment>
    )
  }
}

{
  /* <a target="_blank"  href="https://www.amazon.com/gp/product/0786965592/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0786965592&linkCode=as2&tag=vango0d-20&linkId=7b8f980be68bf3f3e971e19703ca7c3f"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=0786965592&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=vango0d-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=vango0d-20&l=am2&o=1&a=0786965592" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}

const StyledIFrame = styled.iframe`
  &&& {
    width: 100%;
    height: 100%;
    border: none;

    div {
      background-color: red;
    }
    div .amazon .amzn-ad-container {
      .amzn-ad-logo-holder {
        display: none;
      }
    }
  }
`

export const IFrameListing = ({ASIN = 'B07MGDXWS3'}) => {
  return (
    <ListingContainer>
      <StyledIFrame
        // style={{ width: 120, height: 240 }}
        marginwidth="0"
        marginheight="0"
        scrolling="no"
        frameborder="0"
        src={`//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=vango0d-20&marketplace=amazon&region=US&placement=${ASIN}&asins=${ASIN}&linkId=e61ff93f77518911361766684e298a24&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff`}
      ></StyledIFrame>
    </ListingContainer>
  )
}
{
  /* <a target="_blank"  href="https://www.amazon.com/gp/offer-listing/B07MGDXWRY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07MGDXWRY&linkCode=am2&tag=vango0d-20&linkId=4ed55cebfea9a59563c72033044f54d6"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07MGDXWRY&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=vango0d-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=vango0d-20&l=am2&o=1&a=B07MGDXWRY" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}
{
  /* <a target="_blank"  href="https://www.amazon.com/gp/offer-listing/B07MGDXWRY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07MGDXWRY&linkCode=am2&tag=vango0d-20&linkId=81f9b66993e3ac4f92c6dc5ecb843b38"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07MGDXWRY&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=vango0d-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=vango0d-20&l=am2&o=1&a=B07MGDXWRY" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}

export default AmazonDetailListing
